import styled from "styled-components";

export default styled.label.attrs((props) => ({
	style: {
		margin: props.margin,
	},
}))`
	font-family: Poppins;
	font-size: 16px;
	font-weight: 400;
	/* font-size: 20px; */
	line-height: 15px;
	margin-bottom: 1px;	
	color: ${(props) => props.color || "#888888" } ;
	padding: 10px;
`;
