import React from "react";

import { NavLink } from "react-router-dom";
import { Layout, FileText, Monitor, Heart, Tool } from "react-feather";
import styled from "styled-components";

import logo from "../../../img/evt.svg";
import UserMenu from "../../Layout/UserMenu/UserMenu";
import Tooltip from "../Tooltip/Tooltip";
import PermissionGate from "./../../PermissionGate";


function SidebarMenu(props) {
	const SidebarMenu = styled.div`
		display: flex;
		flex-direction: column;
		align-items: center;
		align-content: space-between;
		background: #132135;
		height: 100%;
	`;

	const Logo = styled.img`
		padding: 10px;
		width: 70%;
	`;

	const Links = styled.div`
		flex-grow: 1;
		display: flex;
		flex-direction: column;
		justify-content: center;
		width: 100%;
	`;

	const LinkWrapper = styled.div`
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-self: center;
		align-items: center;
		width: 100%;
		text-align: center;

		&.active {
			border-left: 3px solid white;
		}

		:hover {
			color: white;
		}

		&:hover ${Tooltip} {
			visibility: visible;
		}
	`;

	const Link = styled(NavLink)`
		width: 100%;
		display: block;
		margin: auto;
		margin: 10px;
		font-size: 1.3rem;
		color: rgba(255, 255, 255, 0.6);

		:hover {
			color: white;
		}

		&.active {
			color: white;
		}
	`;

	const pathname = window.location.pathname;
	const path = window.location.pathname.split("/");
	const module = path[1];

	return (
		<SidebarMenu>
			<Logo src={logo} />
			<Links>
				<>
					<LinkWrapper
						className={`${pathname.includes("/monitor") && "active"
							}`}
					>
						<Link
							className={`${pathname.includes("/monitor") && "active"
								}`}
							to="/monitor"
						>
							<Monitor />
						</Link>
						<Tooltip right>Monitor NF3e</Tooltip>
					</LinkWrapper>
					<LinkWrapper
						className={`${pathname.includes("/relatorios") && "active"
							}`}
					>
						<Link
							className={`${pathname.includes("/relatorios") && "active"
								}`}
							to="/relatorios"
						>
							<FileText />
						</Link>
						<Tooltip right>Relatórios</Tooltip>
					</LinkWrapper>
					<LinkWrapper
						className={`${pathname.includes("/certificados") && "active"
							}`}
					>
						<Link
							className={`${pathname.includes("/certificados") && "active"
								}`}
							to="/certificados"
						>
							<Layout />
						</Link>
						<Tooltip right>Certificados</Tooltip>
					</LinkWrapper>
					<LinkWrapper
						className={`${pathname.includes("/saude") && "active"}`}
					>
						<Link
							className={`${pathname.includes("/saude") && "active"
								}`}
							to="/saude"
						>
							<Heart />
						</Link>
						<Tooltip right>Saúde</Tooltip>
					</LinkWrapper>

					<PermissionGate permissions={['canSeeNf3eTools']}>
						<LinkWrapper
							className={`${pathname.includes("/nf3etools") && "active"}`}
						>
							<Link
								className={`${pathname.includes("/nf3etools") && "active"
									}`}
								to="/nf3etools"
							>
								<Tool />
							</Link>
							<Tooltip right>Nf3eTools</Tooltip>
						</LinkWrapper>
					</PermissionGate>
					</>
				</Links>
				<UserMenu user={props.user} module={module} />
		</SidebarMenu>
	);
}

export default SidebarMenu;
