import React, { useState, useEffect } from "react";
import styled from "styled-components";

import Button from "../Button/Button";
import ButtonGroup from "../ButtonGroup/ButtonGroup";
import Table from "../Table/Table";

export default function Modal(props) {
	const Dialog = styled.dialog`
		position: absolute;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		max-width: 50%;
		min-width: 500px;
		display: ${props.showModal ? "flex" : "none"};
		background: #ffffff;
		box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
		border-radius: 20px;
		border: 0;
		z-index: 2;
	`;

	const Title = styled.span`
		font-family: Poppins;
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		line-height: 24px;
		text-align: center;
		color: #282828;
		margin: 10px;
	`;

	const Subtitle = styled.span`
		font-family: Poppins;
		font-style: normal;
		font-weight: 500;
		font-size: 12px;
		line-height: 18px;
		text-align: center;
		color: #888888;
		margin-bottom: 20px;
	`;
	const [linforNfe, setInfoNfe] = useState({});
	const [nfe, setNfe] = useState([]);


	return (
		<Dialog className="dialog">

			{ props.modalTitle && (
				<Title>{props.modalTitle}</Title>
			)}

			{ props.modalBody && (
				<Subtitle>{props.modalBody}</Subtitle>
			)}
			
			{ props.children && (
				props.children
			)}

			<ButtonGroup>
				{!props.hideFirstButton && (
					<Button
						type="button"
						onClick={props.handleToggleModal}
						cancel
					>
						Cancelar
					</Button>
				)}
				{!props.hideSecondButton && (
					<Button
						type="button"
						onClick={props.handleConfirmModalButton}
						danger
					>
						Fechar
					</Button>
				)}
			</ButtonGroup>
		</Dialog>
	);
}
