import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { nfeAxios } from "../../utils/axiosInstances";
import Header from "../../components/Layout/Header/Header";
import Loading from "../Layout/Loading/Loading";
import { CheckCircle, AlertCircle, XCircle } from "react-feather";
import Label from "../../components/Layout/Label/Label"
import Form from "../Layout/Form/Form";
import FormGroup from "../Layout/FormGroup/FormGroup";
import FieldSet from "../Layout/FieldSet/FieldSet";
import Card from "../Layout/Card/Card";
import TrackingGroup from "../Layout/TrackingGroup/TrackingGroup";
import { isAfter, formatDistance } from 'date-fns';


function SaudePage(props) {

	const optionsBorder = (opt) => ({
		'true': '#23c85d',
		'false': '#dc3545',
		'danger': '#dc3545',
		'success': '#23c85d',
		'0': '#23c85d',
		'1': '#ffea00',
		'2': '#dc3545',
		'blue': '#0000FF',
		'yellow': '#ffea00',
		'orange': '#FFA500'
	})[opt] || "black";

	const Dashboard = styled.div`
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		min-width: 200px;
		min-height: 150px;
	`;
	const StyledCard = styled(Card)`
		max-width: max-content;
		z-index: 0;
		overflow: hidden;
		position: relative;
		min-height: 745px;
		min-width: 470px;
		margin-bottom: 1%;
		flex-direction: column;
	`;

	const Column = styled.div`
		display: flex;
		flex-direction: row;
		flex-grow: 1;
		flex-wrap: wrap;
	`;

	const Item = styled.div`
		display: flex;
		flex-direction: ${(props) => (props.column ? "column" : "row")};
		margin: 1px;
		flex-grow: 1;
		min-height: 50px;
		max-height: 83px;
		min-width: 290px;
		max-width: 400px;
		color: #504d4d;
		border: 1px solid #504d4d;
		border-radius: 4px;
		justify-content: center;
		align-items: left;
		background: "#cccccc";
		font-size: 22px;
		margin-bottom: 12px;
		border-left: 6px solid ${(props) => optionsBorder(String(props.status))};
	`;

	const ItemSpace = styled.div`
		display: flex;
		flex-direction: ${(props) => (props.column ? "column" : "row")};
		margin: 1px;
		flex-grow: 1;
		min-height: 50px;
		max-height: 83px;
		min-width: 290px;
		max-width: 400px;
		align-items: left;
	`;
	const Body = styled.div`
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		padding: 5px;
	`;

	const ContainerGeneral = styled.div`
		display: flex;
		align-items: flex-start;
		align-content: flex-start;
	`;


	const optionsColor = (opt) => ({
		'success': '#23c85d',
		'warning': '#ffea00',
		'danger': "#dc3545",
		'0': '#23c85d',
		'1': '#dc3545',
		'2': '#ffea00',
	})[opt] || "black";

	const Text = styled.div`
		display: flex;
		font-size: 16px;
		
		color: ${(props) => optionsColor(props.status)};
			
		padding-top: 10px;
		align-items: center;		
	`;

	const [loading, setLoading] = useState(true);
	const [health, setHealth] = useState({}); // ► chamada n.1
	const [health2, setHealth2] = useState({}); // ► chamada n.2 e assim por diante-criar as demais chamadas
	const [token, setToken] = useState(props.session.token)		
	nfeAxios.defaults.headers = { 'Authorization': `Bearer ${token}` }

	useEffect(() => {
		async function getHealth() {
			const response = await nfeAxios.get(`Nf3eHealthService`);

			if (Boolean(response.data?.expiredCertificateDate)) {

				const dateNow = new Date();
				const dateToCompare = new Date(response.data.expiredCertificateDate);
				// const dateToCompare = new Date("2022-04-06T21:47:40.1291455-03:00");

				const isExpiredCertificate = isAfter(
					dateNow, // date
					dateToCompare, //dateToCompare
				)
				Object.assign(response.data, { isExpiredCertificate })
			}

			setHealth(response.data || {});
			setLoading(false);
		}
		setLoading(true);
		getHealth();
		setLoading(false);
	}, []);

	const checkStatus =
		health.saas !== 0 && health.saas !== 1 && health.saas !== 2;

	const date1 = new Date();
	const date2 = new Date(health.expiredCertificateDate);
	const diffTime = Math.abs(date2 - date1);
	const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

	const certificateCloseToExpire = diffDays > 0 && diffDays <= 30;

	const SefazData = health.healthResponsesByState || [];
	const RenderSefaz = () => SefazData.map(item => {
		const { contingency, processing, stateName, healthResponseStatusList } = item;
		const {status, statusId, dtCreated } = healthResponseStatusList[0]
		return (Object.keys(health).length && (
			<FormGroup>
				<TrackingGroup
					name={`Estado: ${stateName}`}
					health={health}
					processing={processing}
					contingency={contingency}
					statusId={String(statusId)}
					status={status}
					dtCreated={dtCreated}
					statusList={healthResponseStatusList}
				/>
			</FormGroup>
		));
	});
	return (
		<>
			<ContainerGeneral>
				<StyledCard>
					<Header title="Monitoramento Geral" />
					<Form flexFlow="row wrap">
						<FormGroup>
							<Item column status={health.saas}>
								{checkStatus ? (
									<Loading loading={true} />
								) : (
									<>
										<Label color={"#504d4d"}>Indicadores de saúde do SaaS</Label>
										<Body>
											{health.saas === 0 && (
												<Text status={"success"}>
													Em operação{" "}
													<CheckCircle
														style={{ marginLeft: 5 }}
													/>
												</Text>
											)}
											{health.saas === 1 && (
												<Text status={"warning"}>
													Parcialmente em operação{" "}
													<AlertCircle
														style={{ marginLeft: 5 }}
													/>
												</Text>
											)}
											{health.saas === 2 && (
												<Text status={"danger"}>
													Fora de operação{" "}
													<XCircle style={{ marginLeft: 5 }} />
												</Text>
											)}
										</Body>
									</>
								)}
							</Item>
							<Item status={health.isExpiredCertificate ? "danger" : "success"}>
								{checkStatus ? (
									<Loading loading={true} />
								) : (
									<>
										<Label color={"#504d4d"}>
											Certificados a vencer
											<Text>
												Próximo vencimento:{" "}
											</Text>
											<Text status={health.isExpiredCertificate ? "danger" : "success"}>
												{new Date(
													health.expiredCertificateDate
												).toLocaleString(["pt-BR"])}
											</Text>
										</Label>
										<Body>
											{health.expiredCertificate && (
												<Text status={"warning"}>
												</Text>
											)}
											{!health.expiredCertificate && (
												<Text status={"success"}>
												</Text>
											)}
										</Body>
									</>
								)}
							</Item>
						</FormGroup>
					</Form>
				</StyledCard>

				<StyledCard>
					<Header title="Monitoramento Envio Nf3e" />
					<Form flexFlow="row wrap">
						<FormGroup>
						<Item column status={'blue'} >
								{checkStatus ? (
									<Loading loading={true} />
								) : (
									<>
										<Label color={"#504d4d"}>Última atualização</Label>
										<Body>
											{health?.statusNf3e.lastUpdated && (
												<Text >
													{`Data: ${health?.statusNf3e.lastUpdated || Date.now}`}
												</Text>
											)}
										</Body>
									</>
								)}
							</Item>
							<Item column status={'blue'} >
								{checkStatus ? (
									<Loading loading={true} />
								) : (
									<>
										<Label color={"#504d4d"}>Notas Recebidas</Label>
										<Body>
											<Text >

												{health?.statusNf3e
													? `Quantidade: ${health?.statusNf3e.invoicesReceived || 0}`
													: 'Não possui'
												}
											</Text>
										</Body>
									</>
								)}
							</Item>
							<Item column status={'blue'} >
								{checkStatus ? (
									<Loading loading={true} />
								) : (
									<>
										<Label color={"#504d4d"}>Notas Consultadas</Label>
										<Body>
											
												<Text >

												{health?.statusNf3e
													? `Quantidade: ${health?.statusNf3e.invoicesConsulted || 0}`
													: 'Não possui'
												}
											</Text>
											
										</Body>
									</>
								)}
							</Item>
							<ItemSpace>

							</ItemSpace>
							<Item column status={'blue'} >
								{checkStatus ? (
									<Loading loading={true} />
								) : (
									<>
										<Label color={"#504d4d"}>Notas Aprovadas</Label>
										<Body>
											<Text >

												{health?.statusNf3e
													? `Quantidade: ${health?.statusNf3e.invoicesAuthorized || 0}`
													: 'Não possui'
												}
											</Text>
										</Body>
									</>
								)}
							</Item>

							<Item column status={'danger'} >
								{checkStatus ? (
									<Loading loading={true} />
								) : (
									<>
										<Label color={"#504d4d"}>Notas Rejeitadas</Label>
										<Body>
											<Text >

												{health?.statusNf3e
													? `Quantidade: ${health?.statusNf3e.invoicesRejected || 0}`
													: 'Não possui'
												}
											</Text>
										</Body>
									</>
								)}
							</Item>
							

							<Item column status={'yellow'} >
								{checkStatus ? (
									<Loading loading={true} />
								) : (
									<>
										<Label color={"#504d4d"}>Notas em Processamento</Label>
										<Body>
											<Text >

												{health?.statusNf3e
													? `Quantidade: ${health?.statusNf3e.invoicesInProcess || 0}`
													: 'Não possui'
												}
											</Text>
										</Body>
									</>
								)}
							</Item>

							<Item column status={'orange'} >
								{checkStatus ? (
									<Loading loading={true} />
								) : (
									<>
										<Label color={"#504d4d"}>Notas Duplicadas</Label>
										<Body>
											<Text >

												{health?.statusNf3e
													? `Quantidade: ${health?.statusNf3e.invoicesDuplicated || 0}`
													: 'Não possui'
												}
											</Text>
										</Body>
									</>
								)}
							</Item>
						
						</FormGroup>
					</Form>
				</StyledCard>
			</ContainerGeneral>

			<ContainerGeneral>
				<Form flexFlow="row wrap">
					<Header title="Monitoramento SEFAZ" />
					{RenderSefaz()}
				</Form>
			</ContainerGeneral>
		</>
	);
}

export default SaudePage;
