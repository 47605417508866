import React,{ useCallback, useEffect, useState } from 'react'
import { useGetUserPermissions } from "../hooks/Auth";

const CheckIfHasSessionAfterMS = async (props) => {
    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    async function hasUltimateSession() {
        let session = null
        for (let i = 0; i < 4; i++) {
            console.log(`Waiting ${i} seconds...`);

            session = sessionStorage.getItem('session')
            if (Boolean(session)) {
                console.log('Done - session:', session);
                break;
            }
            await sleep(i * 1000);
        }
        // console.log('Done');
        return Boolean(session);
    }
    const hasSession = await hasUltimateSession();
    // hasSession
    //   ? console.log('tem sessão')
    //   : console.log('NÃO TEM SESSÃO')

    return hasSession
}


function PermissionGate(props) {
    const { children, permissions } = props;
    const [hasPermission, setHasPermission] = useState(false);

    useEffect(() => {
        (async () => {
            const hasSession = await CheckIfHasSessionAfterMS();
            setHasPermission(hasSession)
        })()
    }, []);

    const userPermissions = useGetUserPermissions();

    if (!hasPermission) { 
        return null
     }

    console.log('userPermissions', userPermissions)
    console.log('permissions escolhido:', permissions)

    if (permissions.some((permission) => userPermissions.includes(permission))) {
        console.log('Liberou:', permissions)
        return children
    }
    return null

}

export default PermissionGate;