import styled from "styled-components";

export default styled.div`
	display: flex;
	flex-direction: ${(props) => props.row ? 'row' : 'column'};
	margin: 5px;
	flex: 1;
	max-width: ${(props) => props.maxW ?  props.maxW+'px' : '250px'};
	min-width: ${(props) => props.minW ?  props.minW+'px' : '150px'};
	${(props) => props.mt ? `margin-top: ${props.mt}px` : '' }
`;
