import React from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import { FilterIcon, XIcon, UploadCloudIcon } from "../Icon/Icon";
import Button from "../Button/Button";

const Container = styled.div`
	display: flex;
	justify-content: space-between;
	margin-bottom: 15px;
	width: 100%;
`;

const LeftPanel = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	width: 50%;
`;
const RightPanel = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-end;
	width: 50%;
`;

const Title = styled.div`
	font-family: Poppins;
	font-style: normal;
	font-weight: bold;
	font-size: 1.3rem;
	line-height: 30px;
	align-self: center;
`;

function AssetsMapHeader(props) {
	return (
		<Container>
			<LeftPanel>
				<Title>{props.title}</Title>
			</LeftPanel>

			<RightPanel>
				{props.showSendDeleteButton && (
					<>
						<Button
							type="button"
							title="Reenviar Notas"
							success
							small
							onClick={() => props.handleSendDeleteButton("send")}
						>
							<UploadCloudIcon />
						</Button>
						<Button
							type="button"
							title="Excluir Notas"
							danger
							small
							onClick={() =>
								props.handleSendDeleteButton("delete")
							}
						>
							<XIcon />
						</Button>
					</>
				)}
			</RightPanel>
		</Container>
	);
}

export default withRouter(AssetsMapHeader);
