import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

import styled from "styled-components";
import Header from "../../components/Layout/Header/Header";
import Table from "../../components/Layout/Table/Table";
import { nfeAxios } from "../../utils/axiosInstances";
import FieldSet2 from "../Layout/FieldSet/FieldSet2";
import FormGroup2 from "../Layout/FormGroup/FormGroup2";
import { Select, InputText } from "../Layout/Input/Input";
import Label from "../Layout/Label/Label";
import useDynamicForm from "../../hooks/useDynamicForm";
import Button from "../Layout/Button/Button";
import { InfoIcon } from "../Layout/Icon/Icon";
import Modal from "../Layout/Modal/Modal";
import Pagination from "../../components/Layout/Table/Pagination";

function Relatorio(props) {
	const REPORT_WITH_DETAILS = "ReportNf3eToCsv";
	const BASIC_REPORT = "GenerateNF3eGridReport";
	const [loading, setLoading] = useState(false);
	const [reports, setReports] = useState([]);
	const { fields, setFields, handleInputChange } = useDynamicForm();
	const [filterButton, setFilterButton] = useState(false);
	const [showModal, setShowModal] = useState(false);
	const [tableRow, setTableRow] = useState({});
	const [pagination, setPagination] = useState({
		pageIndex: 0,
		pageSize: 15,
		totalCount: 0,
		totalPages: 0,
	});
	const [reloadPage, setReloadPage] = useState(true);
    const [token, setToken] = useState(props.session.token)
	nfeAxios.defaults.headers = { 'Authorization': `Bearer ${token}` }

	useEffect(() => {
	async function getReports() {

		const Params = { params: {} }
		const argsParams = {};

		if (fields?.start) {
			Object.assign(Params.params, { start: fields.start })
		}
		if (fields?.end) {
			Object.assign(Params.params, { end: fields.end })
		}
		if (fields?.cnpj) {
			Object.assign(Params.params, { cnpj: fields.cnpj })
		}
		
		Object.assign(Params.params, { PageIndex: pagination.pageIndex })
		Object.assign(Params.params, { Limit: pagination.pageSize })		
		Object.assign(Params.params, { ...argsParams })		
		
		const response = await nfeAxios.get(`ReportNf3e`, Params);

		const object = response.data.data;

		if (object) {
			setReports(object || []);
			setPagination(
				{
					pageIndex: response.data.pageIndex,
					pageSize: response.data.pageSize,
					totalCount: response.data.totalCount,
					totalPages: response.data.totalPages,
				} || {}
			);
		}

		

		setLoading(false);
	}
		setLoading(true);
		reloadPage && getReports();
		setReloadPage(false);
	}, [reloadPage]);

	async function getReports() {

		const Params = { params: {} }
		const argsParams = {};

		if (fields?.start) {
			Object.assign(Params.params, { start: fields.start })
		}
		if (fields?.end) {
			Object.assign(Params.params, { end: fields.end })
		}
		if (fields?.cnpj) {
			Object.assign(Params.params, { cnpj: fields.cnpj })
		}

		const response = await nfeAxios.get(`ReportNf3e`, {
			params: {
				PageIndex: pagination.pageIndex,
				Limit: pagination.pageSize,
				start: fields.start,
				end: fields.end,
				cnpj: fields.cnpj,
				...argsParams,
			}
		});

		const object = response.data.data;

		if (object) {
			setReports(object || []);
			setPagination(
				{
					pageIndex: response.data.pageIndex,
					pageSize: response.data.pageSize,
					totalCount: response.data.totalCount,
					totalPages: response.data.totalPages,
				} || {}
			);
		}

		

		setLoading(false);
	}

	async function handleDownloadButtonClick(routeParam) {
		const Params = { params: {} }
		const argsParams = {};

		if (fields?.start) {
			Object.assign(Params.params, { start: fields.start })
		}
		if (fields?.end) {
			Object.assign(Params.params, { end: fields.end })
		}
		if (fields?.cnpj) {
			Object.assign(Params.params, { cnpj: fields.cnpj })
		}

		setLoading(true);
		const response = await nfeAxios.get(`${routeParam}`, {
			params: {
				start: fields.start,
				end: fields.end,
				cnpj: fields.cnpj,
				...argsParams,
			}
		});
		if (response.status === 200) {
			const pom = document.createElement("a");
			pom.setAttribute(
				"href",
				"data:application/octet-stream," +
				encodeURIComponent(response.data)
			);
			pom.setAttribute("download", "data.csv");
			if (document.createEvent) {
				var event = document.createEvent("MouseEvents");
				event.initEvent("click", true, true);
				pom.dispatchEvent(event);
			} else {
				pom.click();
			}

			setLoading(false);
		} else {
			setLoading(false);
			toast.error("Ocorreu um erro, tente novamente.");
		}
	}

	function firstPage() {
		setPagination({ ...pagination, pageIndex: 0 });
		setReloadPage(true);
	}

	function previousPage() {
		setPagination({ ...pagination, pageIndex: pagination.pageIndex - 1 });
		setReloadPage(true);
	}

	function nextPage() {
		setPagination({ ...pagination, pageIndex: pagination.pageIndex + 1 });
		setReloadPage(true);
	}


	const columns = [
		{
			Header: "Data",
			accessor: "date",
			Cell: ({ cell }) => {
				const { original } = cell.row;
				return new Date(original.date).toLocaleString(["pt-BR"]);
			},
		},
		{
			Header: "CNPJ",
			accessor: "cnpj",
		},
		{
			Header: "Notas aprovadas",
			accessor: "totalApproved",
		},
		{
			Header: "Notas rejeitadas",
			accessor: "totalRejected",
		},
		{
			Header: "Total",
			accessor: "total",
		},
		{
			Header: "Detalhes",
			accessor: "detalhes",


			Cell: ({ cell }) => {
				const { original } = cell.row;
				return (
					<>
						<Button
							style={{ height: 30, width: 50 }}
							type="button"
							small
							color="white"
							title="Mais informações"
							onClick={() => {
								setTableRow(original);
								handleToggleModal();
							}}
						>
							<InfoIcon />
						</Button>

					</>
				);
			},
		},
	];

	const handleResetButton = () => {
		setFields({
			key: "",
			document: "",
			end: "",
			event: "",
			start: "",
			state: "",
			status: "",
		});

		setFilterButton(!filterButton);
	};

	const handleToggleModal = () => {
		setShowModal(!false);
	};
	const handleConfirmModalButton = () => {
		setShowModal(false);
	};

	const SpanScroll = styled.span`
	overflow:auto;
	max-height: 300px;
	width: 100%;

	p:hover {
		border-bottom: 1px dotted #000;
	}
`;

	return (
		<>
			<Modal
				showModal={showModal}
				modalTitle="Mais informações"
				handleToggleModal={handleToggleModal}
				handleConfirmModalButton={handleConfirmModalButton}
				hideFirstButton
			>
				{(tableRow?.statusList?.length)
					? <SpanScroll>
						{tableRow.statusList.map((item) => {
							return (<p>Status: {item.status || 'N/C'} - Quantidade: {item.quantity}</p>)
						})}
					</SpanScroll>
					: ('Nenhum dado retornado')
				}

			</Modal>
			<Header
				title="Relatórios"
				showDownloadButton
				handleDownloadButtonClick={() => handleDownloadButtonClick(REPORT_WITH_DETAILS)}
				handleBasicReportButtonClick={() => handleDownloadButtonClick(BASIC_REPORT)}
			/>
			<FieldSet2 style={{ marginLeft: "5px" }}>
				<FormGroup2>
					<Label htmlFor="start">Data Inicial</Label>
					<InputText
						id="start"
						type="date"
						name="start"
						value={fields.start}
						onChange={handleInputChange}
					/>
				</FormGroup2>

				<FormGroup2>
					<Label htmlFor="end">Data Final</Label>
					<InputText
						id="end"
						type="date"
						name="end"
						value={fields.end}
						onChange={handleInputChange}
					/>
				</FormGroup2>
				<FormGroup2>
					<Label htmlFor="cnpj">CNPJ</Label>
					<InputText
						id="cnpj"
						type="text"
						name="cnpj"
						value={fields.cnpj}
						onChange={handleInputChange}
					/>
				</FormGroup2>

				<FormGroup2 maxW='90' minW='90' mt='20'>
					<Label htmlFor="buscar"></Label>
					<Button
						id='buscar'
						type="button"
						// onClick={() => setFilterButton(!filterButton)}
						onClick={() => getReports()}
						medium
						primary
					>
						Filtrar
					</Button>
				</FormGroup2>

				<FormGroup2 maxW='90' minW='90' mt='20'>
					<Label htmlFor="limpar"></Label>
					<Button
						id='limpar'
						type="button"
						onClick={handleResetButton}
						medium
						cancel
					>
						Limpar
					</Button>
				</FormGroup2>

			</FieldSet2>
			<Table data={reports} columns={columns} loading={loading} />
			<Pagination
				data={pagination}
				firstPage={firstPage}
				previousPage={previousPage}
				nextPage={nextPage}
			/>
		</>
	);
}

export default Relatorio;