import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { nfeAxios } from "../../../utils/axiosInstances";
import Header from "../Header/Header";
import Loading from "../Loading/Loading";
import { CheckCircle, AlertCircle, XCircle } from "react-feather";
import Label from "../Label/Label";
import Form from "../Form/Form";
import FormGroup from "../FormGroup/FormGroup";
import Card from "../Card/Card";
import Collapsible from "../Collapsible/Collapsible";

function TrackingGroup(props) {
  const { name, contingency, statusId, status, statusList, health, dtCreated } =
    props;
  const [loading, setLoading] = useState(true);

  const optionsBorder = (opt) =>
    ({
      true: "#23c85d",
      false: "#dc3545",
      0: "#23c85d",
      1: "#ffea00",
      2: "#dc3545",
    }[opt] || "black");

  const Dashboard = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    min-width: 200px;
    min-height: 150px;
  `;
  const StyledCard = styled(Card)`
    max-width: max-content;
    margin: auto;
    z-index: 0;
    overflow: hidden;
    position: relative;
    width: 100%;
    margin-bottom: 1%;
  `;

  const Column = styled.div`
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    flex-wrap: wrap;
  `;

  const Item = styled.div`
    display: flex;
    flex-direction: ${(props) => (props.column ? "column" : "row")};
    margin: 1px;
    flex-grow: 1;
    min-height: 50px;
    width: 300px;
    color: #504d4d;
    border: 1px solid #504d4d;
    border-radius: 4px;
    justify-content: center;
    align-items: left;
    background: "#cccccc";
    font-size: 22px;
    border-left: 6px solid ${(props) => optionsBorder(String(props.status))};
    margin-bottom: 12px;
  `;

  const Body = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 5px;
    min-width: 290px;
  `;

  const Line = styled.div`
    border-bottom: 1px solid #504d4d;
    margin: 5px 0px;
  `;

  const optionsColor = (opt) =>
    ({
      success: "#23c85d",
      warning: "#ffea00",
      danger: "#dc3545",
      0: "#23c85d",
      1: "#dc3545",
      2: "#ffea00",
      true: "#23c85d",
      false: "#dc3545",
    }[opt] || "black");

  const Text = styled.div`
    display: flex;
    font-size: 14px;
    color: ${(props) => optionsColor(props.status)};
    padding-top: 8px;
    align-items: center;
  `;

  useEffect(() => {
    setLoading(false);
  }, []);

  const checkStatus =
    health.saas !== 0 && health.saas !== 1 && health.saas !== 2;
  const date1 = new Date();
  const date2 = new Date(health.expiredCertificateDate);
  const diffTime = Math.abs(date2 - date1);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  const certificateCloseToExpire = diffDays > 0 && diffDays <= 30;

  const ItemTracking = (props) => {
    const { statusId, status, statusDate, contingency } = props;
    const statusDateFormatted = new Date(statusDate).toLocaleString(
      navigator.language
    );

    return (
      <>
        <Label color={"#504d4d"}>{`Status: ${statusDateFormatted}`}</Label>
        <Item column status={statusId === "107" ? true : false}>
          {checkStatus ? (
            <Loading loading={true} />
          ) : (
            <>
              <Label color={"#504d4d"}>Serviço SEFAZ</Label>
              <Body>
                {statusId && (
                  <Text status={statusId === "107" ? "success" : "danger"}>
                    {status}{" "}
                    {statusId === "107" ? (
                      <CheckCircle style={{ marginLeft: 5 }} />
                    ) : (
                      <XCircle style={{ marginLeft: 5 }} />
                    )}
                  </Text>
                )}
              </Body>
            </>
          )}
        </Item>
        <Item column status={!contingency}>
          {checkStatus ? (
            <Loading loading={true} />
          ) : (
            <>
              <Label color={"#504d4d"}>Contingência</Label>
              <Body>
                {!contingency && (
                  <Text status={"success"}>
                    Não habilitada <CheckCircle style={{ marginLeft: 3 }} />
                  </Text>
                )}
                {contingency && (
                  <Text status={"danger"}>
                    Habilitada <XCircle style={{ marginLeft: 3 }} />
                  </Text>
                )}
              </Body>
            </>
          )}
        </Item>
      </>
    );
  };

  return (
    <>
      <Form flexFlow="row wrap">
        <StyledCard>
          <FormGroup>
            <Label color={"#504d4d"}>{name}</Label>
            <ItemTracking
              status={status}
              statusId={statusId}
              contingency={contingency}
              statusDate={dtCreated}
            />
            <Line />
            {statusList?.length > 1 && (
              <Collapsible>
                {statusList?.map((statusItem, index) => {
                  return (
                    <>
                      {index !== 0 && (
                        <FormGroup>
                          <ItemTracking
                            status={statusItem.status}
                            statusId={String(statusItem.statusId)}
                            contingency={statusItem.contingency}
                            statusDate={statusItem.dtCreated}
                          />
                        </FormGroup>
                      )}
                    </>
                  );
                })}
              </Collapsible>
            )}
          </FormGroup>
        </StyledCard>
      </Form>
    </>
  );
}

export default TrackingGroup;
