import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { nfeAxios } from "../../../../utils/axiosInstances";
import Header from "../../../Layout/Header/Header";
import Label from "../../../Layout/Label/Label";
import Button from "../../../Layout/Button/Button";
import Table from "../../../Layout/Table/Table";

function Nf3eSchemaFails(props) {

    const [dataFails, setDataFails] = useState(null)
    const [showFails, setShowFails] = useState()
    const [loading, setLoading] = useState(false);
    const [token, setToken] = useState(props.session.token)
    nfeAxios.defaults.headers = { 'Authorization': `Bearer ${token}` }

    const NewSizeTable = styled.div`
    max-width: 50%;
`;

    const Container = styled.div`
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding: 10px 10px ;
        width: 100%;
    `;
    const DivResult = styled.div`
        display: flex;
        flex-direction: column;
        min-width: 300px;
        min-height: 250px;
    `;
    const CleanFrame = styled.div`
        min-width: 660px;
        max-width: 700px;
        min-height: 150px;
        max-height: 280px;
        background-color: #f9f9f9;
        border-radius: 10px;
        box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
    `;
  
    async function GetTodayFailedSchemasNF3e(options = {}) {

        const response = await nfeAxios.get(`GetTodayFailedSchemasNF3e`);
        const { data } = response;

        return data;
    }

    const BuscarFalhas = async () => {
        const retorno = await GetTodayFailedSchemasNF3e();
        // retorno.push({ quantity: 123456, status: 350 })
        setDataFails(retorno);
    }

    const columns = [
        {
            Header: "Status",
            accessor: "status",
        }, {
            Header: "Quantidade",
            accessor: "quantity",
        },

    ];

    const Results = (props) => (
        <>
            <NewSizeTable>
                <Table data={props.data} columns={columns} />
            </NewSizeTable>
        </>
    )

    // função responsável pela exibição
    const handleShowFails = () => {
        if (!dataFails) return;

        const ErrorMessage = () => (<p> Não foram encontradas notas </p>);

        if (!dataFails.length) {
            return <ErrorMessage />
        }

        return <Results data={dataFails} />
    }

    useEffect(() => {
        const retorno = handleShowFails()
        setShowFails(retorno)
        // loading(false)
    }, [dataFails])


    return (
            <CleanFrame>
                <Container>
                    <Header title="Notas com falha no schema" />
                    <Label htmlFor="buscar"></Label>
                    <Button
                        id='buscar'
                        type="button"
                        onClick={() => BuscarFalhas()}
                        medium
                        primary
                    >
                        Buscar
                    </Button>
                    <DivResult>
                        {showFails}
                    </DivResult>
                </Container>
            </CleanFrame>
    );

}

export default Nf3eSchemaFails;