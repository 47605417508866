import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import queryString from "query-string";

import useDynamicForm from "../../../hooks/useDynamicForm";
import FieldSet from "../../Layout/FieldSet/FieldSet";
import Label from "../../Layout/Label/Label";
import { Select, InputText } from "../../Layout/Input/Input";
import Button from "../../Layout/Button/Button";
import FieldSet2 from "../FieldSet/FieldSet2";
import FormGroup2 from "../FormGroup/FormGroup2";
import { FilterIcon, XIcon, UploadCloudIcon } from "../Icon/Icon";

const InputTextFilter = styled(InputText)`

`;

const Container = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	padding: 10px 10px ;
	width: 100%;
`;

function Filter(props) {
	const { fields, setFields, handleInputChange } = useDynamicForm();
	const [filterButton, setFilterButton] = useState(false);
	const [showInfoFilter, setShowInfoFilter] = useState(false)
	const firstTime = useRef(true);

	useEffect(() => {
		const filter = props.filter;
		const filterObject = queryString.parse(filter);

		setFields(filterObject);
	}, [props.filter, setFields]);

	useEffect(() => {
		if (firstTime.current) {
			firstTime.current = false;
			return;
		}
		//--
		const query = Object.keys(fields).map((item) => {
			return (fields[item] !== "") 
				? `${item}=${fields[item]}`
				: ""
		});
		const filtered = query.filter( item => Boolean(item) === true );
		const queryFiltered = filtered.length ? `?${filtered.join('&')}` : '';
		//--

		props.onChangeFilter && props.onChangeFilter(queryFiltered);
	}, [filterButton]);

	const toggleButtonClick = () => {	
		setShowInfoFilter(!showInfoFilter);
	};

	const handleResetButton = () => {
		setFields({
			key: "",
			document: "",
			end: "",
			event: "",
			start: "",
			state: "",
			status: "",
		});

		setFilterButton(!filterButton);
	};

	return (
		<Container>
			<FieldSet2>
					<Label htmlFor="key">Chave NF3e</Label>
					<InputText
						id="key"
						type="text"
						name="key"
						value={fields.key}
						onChange={handleInputChange}
					/>
					{showInfoFilter === false && (
                    <Button
						type="button"
						onClick={() => setFilterButton(!filterButton)}
						medium
						primary
						style={{ marginLeft: "10px" }}
					>
						Pesquisar
					</Button>
					)}

					<Button
						type="button"
						title="Abrir filtro"
						small
						onClick={() => toggleButtonClick()}
						style={{ marginLeft: "4px" }}
					>
					<FilterIcon />
					</Button>
			</FieldSet2>
			{ showInfoFilter && (
			<FieldSet2 style={{ marginLeft: "5px" }}>
				<FormGroup2>
					<Label htmlFor="status">Status</Label>
					<InputText
						id="status"
						type="text"
						name="status"
						value={fields.status}
						onChange={handleInputChange}
					/>
				</FormGroup2>

				<FormGroup2>
					<Label htmlFor="state">UF</Label>
					<Select
						id="state"
						onChange={handleInputChange}
						value={fields.state}
					>
						<option value="">Selecione</option>
						<option value="AC">Acre</option>
						<option value="AL">Alagoas</option>
						<option value="AP">Amapá</option>
						<option value="AM">Amazonas</option>
						<option value="BA">Bahia</option>
						<option value="CE">Ceará</option>
						<option value="DF">Distrito Federal</option>
						<option value="ES">Espírito Santo</option>
						<option value="GO">Goiás</option>
						<option value="MA">Maranhão</option>
						<option value="MT">Mato Grosso</option>
						<option value="MS">Mato Grosso do Sul</option>
						<option value="MG">Minas Gerais</option>
						<option value="PA">Pará</option>
						<option value="PB">Paraíba</option>
						<option value="PR">Paraná</option>
						<option value="PE">Pernambuco</option>
						<option value="PI">Piauí</option>
						<option value="RJ">Rio de Janeiro</option>
						<option value="RN">Rio Grande do Norte</option>
						<option value="RS">Rio Grande do Sul</option>
						<option value="RO">Rondônia</option>
						<option value="RR">Roraima</option>
						<option value="SC">Santa Catarina</option>
						<option value="SP">São Paulo</option>
						<option value="SE">Sergipe</option>
						<option value="TO">Tocantins</option>
					</Select>
				</FormGroup2>

				<FormGroup2>
					<Label htmlFor="event">Evento</Label>
					<InputText
						id="event"
						type="text"
						name="event"
						value={fields.event}
						onChange={handleInputChange}
					/>
				</FormGroup2>
				<FormGroup2>
					<Label htmlFor="document">CNPJ</Label>
					<InputText
						id="document"
						type="text"
						name="document"
						value={fields.document}
						onChange={handleInputChange}
					/>
				</FormGroup2>
			</FieldSet2>
			)}
			{ showInfoFilter && (
			<FieldSet2 style={{ marginLeft: "5px" }}>

				<FormGroup2>
					<Label htmlFor="start">Data Inicial</Label>
					<InputText
						id="start"
						type="date"
						name="start"
						value={fields.start}
						onChange={handleInputChange}
					/>
				</FormGroup2>

				<FormGroup2>
					<Label htmlFor="end">Data Final</Label>

					<InputText
						id="end"
						type="date"
						name="end"
						value={fields.end}
						onChange={handleInputChange}
					/>
				</FormGroup2>

				<FieldSet2>
					<Button
						type="button"
						onClick={() => setFilterButton(!filterButton)}
						large
						primary
					>
						Buscar
					</Button>
					<Button
						type="button"
						onClick={handleResetButton}
						large
						cancel
					>
						Limpar
					</Button>
			
				</FieldSet2>			
			</FieldSet2>
			)}
		</Container>
	);
}

export default Filter;
