import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

import { cnpjMask } from "../../../utils/cnpjMask";
import { nfeAxios } from "../../../utils/axiosInstances";
import useDynamicForm from "../../../hooks/useDynamicForm";
import Header from "../../../components/Layout/Header/Header";
import Form from "../../../components/Layout/Form/Form";
import Card from "../../../components/Layout/Card/Card";
import FormGroup from "../../../components/Layout/FormGroup/FormGroup";
import Label from "../../../components/Layout/Label/Label";
import { InputText, InputFile } from "../../../components/Layout/Input/Input";
import FieldSet from "../../../components/Layout/FieldSet/FieldSet";
import styled from "styled-components";
import Button from "../../../components/Layout/Button/Button";
import ButtonGroup from "../../../components/Layout/ButtonGroup/ButtonGroup";
import Loading from "../../../components/Layout/Loading/Loading";
import Icon from "../../../components/Layout/Icon/Icon";

const StyledCard = styled(Card)`
	max-width: min-content;
	margin: auto;
	z-index: 0;
	overflow: hidden;
	position: relative;
	width: 100%;
	justify-content: center;
	align-items: center;
`;

const LabelFile = styled(Label)`
	background-color: ${(props) => props.theme.primary};
	border-radius: 10px;
	color: #fff;
	cursor: pointer;
	margin: 10px 0px;
	padding: 6px 0px;
	text-align: center;

	:hover {
		background: ${(props) => props.theme.primaryDark};
	}
`;

function CertificadoDetailsPage(props) {
	const { fields, setFields, handleInputChange } = useDynamicForm();
	const [loading, setLoading] = useState(false);
	const [isNew, setIsNew] = useState(getUrlId() === "new");
	const [settingsFlow, setSettingsFlow] = useState({
		cnpj: null,
		expiresOn: null,
		name: null,
	});


	useEffect(() => {
		setSettingsFlow(Object.assign({ settingsFlow }, { ...props.location.state }));
		console.log(props.location.state)
	}, []);


	function getUrlId() {
		let pathName = props.history.location.pathname;
		let stringToArray = pathName.split('/');
		let lastItem = stringToArray[stringToArray.length - 1];
		return lastItem
	};

	const createCertificado = async () => {
		try {

			const createCert = async (fields) => {
				const res = Object.assign({...fields});

				const response = await nfeAxios.post(
					`Nf3eCertificateImport`,
					res
				);
				return response;
				
			};
			const updateCert = async (fields) => {
				
				const res = Object.assign({...fields}, {...settingsFlow});
				delete res.settingsFlow;

				const response = await nfeAxios.post(
					`Nf3eCertificateUpdate`,
					res
				);
				return response;
			}

			const response = isNew ? await createCert(fields) : await updateCert(fields);
			const status = response.status || {};
			setLoading(false);

			if (status === 200) {
				toast.success("Certificado criado com sucesso");
				props.history.push("/certificados");
			} else {
				toast.error("Não foi possível enviar o certificado");
			}
		} catch (err) {
			setLoading(false);
			toast.error("Não foi possível enviar o certificado");
		}
	};

	const handleFormSubmit = (e) => {
		e.preventDefault();
		setLoading(true);

		createCertificado();
	};

	function bytesToSize(bytes) {
		const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
		if (bytes === 0) return "0 Byte";
		const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
		return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
	}

	const handleFileInput = (e) => {
		var f = e.target.files[0]; // FileList object
		var reader = new FileReader();
		// Closure to capture the file information.
		reader.onload = (function (theFile) {
			return function (e) {
				var binaryData = e.target.result;
				//Converting Binary Data to base 64
				var base64String = window.btoa(binaryData);
				//showing file converted to base64
				setFields({
					...fields,
					certificate: base64String,
					file: { name: f.name, size: f.size },
				});
			};
		})(f);
		// Read in the image file as a data URL.
		reader.readAsBinaryString(f);
	};

	return (
		<>
			<Header title="Cadastro de Certificado" showReturnButton />
			<StyledCard>
				<Loading loading={loading} absolute />
				<Form onSubmit={handleFormSubmit} flexFlow="row wrap">
					<FieldSet>
						<FormGroup>
							<LabelFile htmlFor="certificate">
								Escolher arquivo...
							</LabelFile>
							<InputFile
								type="file"
								name="certificate"
								id="certificate"
								onChange={handleFileInput}
							/>
						</FormGroup>
					</FieldSet>
					{fields.certificate && (
						<FieldSet>
							<FormGroup>
								<Label>
									Arquivo: {fields.file.name} -{" "}
									{bytesToSize(fields.file.size)}
								</Label>
							</FormGroup>
						</FieldSet>
					)}

					<FieldSet>
						<FormGroup>
							<Label htmlFor="name">Nome</Label>
							<InputText
								type="text"
								id="name"
								placeholder=""
								defaultValue={fields.name || settingsFlow.name}
								onChange={handleInputChange}
								disabled={!isNew}
							/>
						</FormGroup>
						<FormGroup>
							<Label htmlFor="Password">Senha</Label>
							<InputText
								type="password"
								id="Password"
								placeholder=""
								defaultValue={fields.Password}
								onChange={handleInputChange}
								// disabled={!isNew}								
							/>
						</FormGroup>
					</FieldSet>
					<FieldSet>
						<FormGroup>
							<Label htmlFor="cnpj">CNPJ</Label>
							<InputText
								type="text"
								id="cnpj"
								placeholder=""
								defaultValue={
									fields.cnpj ? cnpjMask(fields.cnpj) : settingsFlow.cnpj
								}
								onChange={handleInputChange}
								disabled={!isNew}
							/>
						</FormGroup>
					</FieldSet>
					<FieldSet justifyContent="flex-end">
						<ButtonGroup>
							<Button type="submit" success>
								Salvar
							</Button>
						</ButtonGroup>
					</FieldSet>
				</Form>
			</StyledCard>
		</>
	);
}

export default CertificadoDetailsPage;
