import axios from "axios";

const sessionToken = () => {
	const session = JSON.parse(sessionStorage.getItem("session"));
	return session && session.token;
};

// For common config
axios.defaults.headers.post["Content-Type"] = "application/json";

// NF3e
const nfeAxios = axios.create({ baseURL: window.API_URI });

nfeAxios.interceptors.request.use((config) => {

	config.params = config.params || {};
	config.params["code"] = window.API_CODE;

	return config;
});

export { nfeAxios };
