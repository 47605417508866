import React, { useState, useEffect, useContext } from "react";
import { nfeAxios } from "../../../utils/axiosInstances";

import { cnpjMask } from "../../../utils/cnpjMask";
import { AuthContext } from "../../../context/AuthContext";
import Header from "../../../components/Layout/Header/Header";
import Table from "../../../components/Layout/Table/Table";
import Button from "../../Layout/Button/Button";
import styled from "styled-components";
import { PlusIcon } from "../../../components/Layout/Icon/Icon";

function CertificadosListPage(props) {
	const { session } = useContext(AuthContext);

	const [loading, setLoading] = useState([]);
	const [certificados, setCertificados] = useState([]);
	const [settingsFlow, setSettingsFlow] = useState({
		cnpj: null,
		expiresOn: null,
		name: null,
	});
	const [token, setToken] = useState(props.session.token)		
	nfeAxios.defaults.headers = { 'Authorization': `Bearer ${token}` }

	useEffect(() => {
		async function getCertificados() {
			const response = await nfeAxios.get(`Nf3eCertificateAllGet`);

			setCertificados(response.data || []);
			setLoading(false);
		}
		setLoading(true);
		getCertificados();
	}, [session]);

	const handleRegisterButtonClick = () => {
		props.history.push({
			pathname: `/certificados/${settingsFlow.cnpj || 'new'}`,
			state: { ...settingsFlow },
		});

	};

	const SmallButton = styled(Button)`
	height: 25px;
	`;

	const columns = [
		{
			Header: "CNPJ",
			accessor: "cnpj",
			width: 100,
			Cell: ({ cell }) => {
				const { original } = cell.row;

				const cnpj = cnpjMask(original.cnpj);

				return cnpj;
			},
		},
		{
			Header: "Certificado",
			accessor: "name",
		},
		{
			Header: "Válido até",
			accessor: "expiresOn",
			Cell: ({ cell }) => {
				const { original } = cell.row;
				return new Date(original.expiresOn).toLocaleString(["pt-BR"]);
			},
		},
		{
			Header: "Upload do Certificado",
			accessor: "id",
			Cell: ({ cell }) => {

				const original = cell.row.original;

				return (
					<SmallButton>
						<PlusIcon
							type="button"
							small
							onClick={() => {
								setSettingsFlow(Object.assign(settingsFlow, original));
								handleRegisterButtonClick()
							}}
						/>
					</SmallButton>);
			},
		},
	];

	return (
		<>
			<Header
				title="Certificados"
				showNewRegisterButton
				handleNewRegisterButtonClick={handleRegisterButtonClick}
			/>
			<Table data={certificados} columns={columns} loading={loading} />
		</>
	);
}

export default CertificadosListPage;
