import React, { useState, useEffect } from "react";
import NoteKey from "./NoteKey";
import Nf3eSchemaFails from "./Nf3eSchemaFail";
import styled from "styled-components";
import UploadFile from "./Upload";


const Frames = styled.div`
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 50px;
    `;

function Nf3eTools(props) {
    return (
        <>
            <Frames>
                <NoteKey session={props.session} />
                <Nf3eSchemaFails session={props.session} />
                <UploadFile />
            </Frames>
        </>
    )
}

export default Nf3eTools;