import useGetNewSessionStorage from "../useGetNewSessionStorage";

const useGetUserPermissions = () => {    

    // lógica pra pegar as permissões da sesssão;
    const newSession = useGetNewSessionStorage()
    // console.log('123 newSession',newSession)
    return newSession?.userPermissions || [];
}

export default useGetUserPermissions;