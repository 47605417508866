import React from "react";
import { Switch, Route, withRouter, Redirect } from "react-router-dom";

import LoginPage from "./components/Login/LoginPage";
import MonitorNfe from "./components/Modules/MonitorNfe";
import Relatorios from "./components/Modules/Relatorios";
import CertificadosListPage from "./components/Modules/Certificado/CertificadosListPage";
import CertificadoDetailsPage from "./components/Modules/Certificado/CertificadoDetailsPage";
import Saude from "./components/Modules/Saude";
import Nf3eTools from "./components/Modules/NF3eTools";

import Layout from "./components/Layout/Layout";
import PasswordPage from "./components/Modules/Password/PasswordPage";
import NotFoundPage from "./components/Modules/NotFound/NotFoundPage";
import { AuthProvider } from "./context/AuthContext";
import PermissionGate from "./components/PermissionGate";

const getSession = () => {
	const session = sessionStorage.getItem('session');
	return session;
}


const isAuthenticated = async () => {
	const session = getSession()
	console.log('isAuthenticated private route', session)
	return Boolean(session);

}

const PrivateRoute = ({ component: Component, ...rest }) => (
	<Route {...rest} render={props => (
		isAuthenticated()
			? (<Component {...props} session={JSON.parse(getSession())} />) 
			: (<Redirect to={{ pathname: '/login', state: { from: props.location } }} />)
	)} />

)


function App() {
	const mockPermissions = {
		Nf3eTools: 'canSeeNf3eTools',
	}

	return (
		<AuthProvider>
			<Switch>

				<Route path="/login" name="LoginPage" component={LoginPage} />
				<Redirect push from="/" exact to="/login" />

				<Layout>


					<Switch>
						<PrivateRoute
							exact
							path="/monitor"
							name="MonitorNfe"
							component={MonitorNfe}
						/>
						<PrivateRoute
							exact
							path="/relatorios"
							name="Relatorios"
							component={Relatorios}
						/>
						<PrivateRoute
							exact
							path="/certificados"
							name="CertificadosListPage"
							component={CertificadosListPage}
						/>
						<PrivateRoute
							exact
							path="/certificados/:id"
							name="CertificadoDetailsPage"
							component={CertificadoDetailsPage}
						/>

						<PrivateRoute
							exact
							path="/saude"
							name="Saude"
							component={Saude}
						/>

						<PermissionGate permissions={[mockPermissions.Nf3eTools]}>						
							<PrivateRoute
								exact
								path="/nf3etools"
								name="Nf3eTools"
								component={Nf3eTools}
							/>
						</PermissionGate>

						<Route
							exact
							path="/(db|app)/password"
							name="PasswordPage"
							component={PasswordPage}
						/>
						<Route component={NotFoundPage} />
					</Switch>

				</Layout>
			</Switch>
		</AuthProvider >
	);
}

export default withRouter(App);
