import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { toast } from "react-toastify";

import md5 from "../../../utils/md5";
import useDynamicForm from "../../../hooks/useDynamicForm";
import { AuthContext } from "../../../context/AuthContext";
import Header from "../../Layout/Header/Header";
import Form from "../../Layout/Form/Form";
import Card from "../../Layout/Card/Card";
import FormGroup from "../../Layout/FormGroup/FormGroup";
import Label from "../../Layout/Label/Label";
import { InputText } from "../../Layout/Input/Input";
import FieldSet from "../../Layout/FieldSet/FieldSet";
import styled from "styled-components";
import Button from "../../Layout/Button/Button";
import ButtonGroup from "../../Layout/ButtonGroup/ButtonGroup";
import Loading from "../../Layout/Loading/Loading";

const StyledCard = styled(Card)`
	max-width: min-content;
	margin: auto;
	z-index: 0;
	overflow: hidden;
	position: relative;
	width: 100%;
	justify-content: center;
	align-items: center;
`;

function PasswordPage(props) {
	const [loading, setLoading] = useState(false);
	const { fields, setFields, handleInputChange } = useDynamicForm();
	const { session } = useContext(AuthContext);

	useEffect(() => {
		setFields({
			currentPassword: "",
			newPassword: "",
			confirmNewPassword: "",
		});
	}, [setFields]);

	const updatePassword = async () => {
		const body = {
			token: session && session.token,
			login: session && session.email,
			senha: md5(fields.newPassword),
		};

		const response = await axios.post(
			"redefineSenha",
			JSON.stringify(body)
		);
		const data = response.data || {};

		setLoading(false);

		if (data.success) {
			toast.success("Senha alterada com sucesso");
			props.history.goBack();
		}
	};

	const handleFormSubmit = (e) => {
		e.preventDefault();

		// if (fields.currentPassword === "") {
		// 	toast.error("Digite a senha atual");
		// 	return;
		// }
		if (fields.newPassword === "") {
			toast.error("Digite a nova senha");
			return;
		} else if (fields.confirmNewPassword === "") {
			toast.error("Confirme a nova senha");
		}

		if (fields.newPassword !== fields.confirmNewPassword) {
			toast.error("Senha nova não coincide");
			return;
		}
		setLoading(true);
		updatePassword();
	};

	const handleCancelButton = () => {
		props.history.goBack();
	};

	return (
		<>
			<Header title="Alterar senha" showReturnButton />
			<StyledCard>
				<Loading loading={loading} absolute />
				<Form onSubmit={handleFormSubmit} flexFlow="row wrap">
					<FieldSet>
						{/* <FormGroup>
							<Label htmlFor="currentPassword">Senha atual</Label>
							<InputText
								type="password"
								id="currentPassword"
								placeholder=""
								onChange={handleInputChange}
							/>
						</FormGroup> */}
						<FormGroup>
							<Label htmlFor="newPassword">Nova senha</Label>
							<InputText
								type="password"
								id="newPassword"
								placeholder=""
								onChange={handleInputChange}
							/>
						</FormGroup>
						<FormGroup>
							<Label htmlFor="confirmNewPassword">
								Confirmar nova senha
							</Label>
							<InputText
								type="password"
								id="confirmNewPassword"
								placeholder=""
								onChange={handleInputChange}
							/>
						</FormGroup>
					</FieldSet>
					<FieldSet justifyContent="flex-end">
						<ButtonGroup>
							<Button
								type="button"
								onClick={handleCancelButton}
								cancel
							>
								Cancelar
							</Button>

							<Button type="submit">Salvar</Button>
						</ButtonGroup>
					</FieldSet>
				</Form>
			</StyledCard>
		</>
	);
}

export default PasswordPage;
