import React, { useState, useEffect } from "react";
import styled from "styled-components";

import { toast } from "react-toastify";
import AssetsMapHeader from "../../components/Layout/Header/AssetsMapHeader";
import Table from "../../components/Layout/Table/Table";
import Pagination from "../../components/Layout/Table/Pagination";
import { nfeAxios } from "../../utils/axiosInstances";
import Filter from "../../components/Layout/Filter/Filter";
import Checkbox from "../../components/Layout/Checkbox/Checkbox";
import { DownloadIcon, RefreshIcon, PlusIcon, InfoIcon } from "../Layout/Icon/Icon";
import Button from "../Layout/Button/Button";
import Card from "../Layout/Card/Card";
import Modal from "../Layout/Modal/Modal";

function MonitorNfe(props) {
	
	const StyledCard = styled(Card)`
		z-index: 0;
		overflow: hidden;
		position: relative;		
		width: 100%;
		justify-content: center;
		align-items: flex-start;
		padding: 10px;
		background-color: #f9f9f9;
	`;

	const Container = styled.div`
		display: flex;
		flex-direction: row;
		width: 100%;
		max-width: 100%;
	`;

	const LeftPanel = styled.div`
		display: flex;
		flex-direction: column;
		width: 100%;
		max-width: 100%;
		transition: width 0.3s;

		&.opened {
			width: 80%;
		}
	`;

	const RightPanel = styled.div`
		display: flex;
		align-items: flex-start;
		position: fixed;
		display: flex;
		right: 0;
		top: 0;
		height: 100vh;
		flex-direction: row;
		width: 0%;
		transition: width 0.3s;
		max-width: 18%;
		background: #ffffff;
		border-radius: 20px 20px 20px 0px;
		box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
		

		&.opened {
			width: 18%;
		}
	`;
	
	const Header = styled.div`
		display: flex;
		flex-direction: column;
		flex-basis: 100px;
	`;

	const Title = styled.span`
		font-family: Poppins;
		font-weight: 600;
		font-size: 14px;
		color: #888888;
	`;

	const [loading, setLoading] = useState(false);
	const [nfe, setNfe] = useState([]);
	const [pagination, setPagination] = useState({
		pageIndex: 0,
		pageSize: 15,
		totalCount: 0,
		totalPages: 0,
	});

	const [showInfoFilter, setShowInfoFilter] = useState("");
	const [showSendDeleteButton, setShowSendDeleteButton] = useState(false);
	const [filter, setFilter] = useState("");
	const [reloadPage, setReloadPage] = useState(true);
	const [showModal, setShowModal] = useState(false);
	const [tableRow, setTableRow] = useState({});
	const [token, setToken] = useState(props.session.token)		
	nfeAxios.defaults.headers = { 'Authorization': `Bearer ${token}` }

	useEffect (() => {
		console.log('tableRow',tableRow)
	},[tableRow, setTableRow]);

	useEffect(() => {
		async function getMonitorNfe() {			
			const {search} = props.history.location
			const argsParams = {};

			
			if (filter) {
				let searchFilter = filter.replace('?','');
				var searchParams = new URLSearchParams(searchFilter);
				for(var [key, value] of searchParams.entries()) {
					argsParams[ key ] = value;			
				}			 
			}
			

			if(Boolean(search) && !Boolean(filter)) {
				var url_string = search.replace('?','');
				var searchParams = new URLSearchParams(url_string);
				for(var [key, value] of searchParams.entries()) {
					argsParams[ key ] = value;			
				}			 
			}

			const response = await nfeAxios.get('Nf3eMonitor',{
				params: {
					PageIndex: pagination.pageIndex,
					Limit: pagination.pageSize,
					...argsParams,
				}
			});

			const object = response.data;

			if (object) {
				setNfe(object.data || []);
				setPagination(
					{
						pageIndex: object.pageIndex,
						pageSize: object.pageSize,
						totalCount: object.totalCount,
						totalPages: object.totalPages,
					} || {}
				);
			}

			setLoading(false);
		}

		setLoading(true);
		reloadPage && getMonitorNfe();
		setReloadPage(false);
	}, [reloadPage, filter]);

	useEffect(() => {
		const findNfe = nfe.find((x) => x.checked);

		if (findNfe) {
			setShowSendDeleteButton(true);
		} else {
			setShowSendDeleteButton(false);
		}
	}, [nfe]);

	const handleButtonClick = (button) => {
		let infoOrFilter = button;

		if (showInfoFilter === button) {
			infoOrFilter = "";
		}

		setShowInfoFilter(infoOrFilter);
	};

	const onChangeFilter = (filtro) => {
		if (filtro !== filter) {
			window.history.replaceState(
				filtro,
				"Monitor NF3e",
				`${props.history.location.pathname}${filtro}`
			);
	
			setPagination({ ...pagination, pageIndex: 0 });			
			
			setFilter(filtro);
			setReloadPage(true);
		}
	};

	function handleInputChange(e, key, id) {
		const result = nfe.map((item) => {
			if (item.key === key) {
				item[`${id}`] = e.target.checked;
			}
			return item;
		});
		setNfe(result);
	}

	async function handleSendNfe(list) {
		setLoading(true);
		const response = await nfeAxios.post(`Nf3eReenvio`, {
			key: list,
		});

		if (response.status === 200) {
			setLoading(false);
			toast.success("NF3e reenviada com sucesso");
			setReloadPage(true);
		} else {
			setLoading(false);

			toast.error("Ocorreu um erro, tente novamente.");
		}
	}

	async function handleDeleteNfe(list) {
		setLoading(true);
		const response = await nfeAxios.post(`Nf3eRemoveFila`, {
			key: list,
		});

		if (response.status === 200) {
			setLoading(false);
			toast.success("NF3e excluída com sucesso");
			setReloadPage(true);
		} else {
			setLoading(false);
			toast.error("Ocorreu um erro, tente novamente.");
		}
	}

	function handleSendDeleteButton(type) {
		const result = nfe.filter((item) => item.checked).map((x) => x.key);

		if (type === "send") {
			handleSendNfe(result);
		} else {
			handleDeleteNfe(result);
		}
	}

	function downloadXmlFile(key, body) {
		var xmltext = body;
		var filename = `Nf3e${key}.xml`;
		var pom = document.createElement("a");
		var bb = new Blob([xmltext], { type: "text/plain" });

		pom.setAttribute("href", window.URL.createObjectURL(bb));
		pom.setAttribute("download", filename);

		pom.dataset.downloadurl = ["text/plain", pom.download, pom.href].join(
			":"
		);
		pom.draggable = true;
		pom.classList.add("dragout");

		pom.click();
	}

	async function handleDownloadClick(key) {
		setLoading(true);
		const response = await nfeAxios.post(`Nf3eDownload`, {
			key: [key],
		});

		if (response.status === 200) {
			downloadXmlFile(key, response.data);

			setLoading(false);
			toast.success("NF3e baixada com sucesso");
		} else {
			setLoading(false);
			toast.error("Ocorreu um erro, tente novamente.");
		}
	}

	async function handleReprocessClick(key) {
		setLoading(true);
		const response = await nfeAxios.get(`Nf3eReprocessar?key=${key}`);

		if (response.status === 200) {
			setLoading(false);
			toast.success("NF3e reprocessada com sucesso");
		} else {
			setLoading(false);
			toast.error("Ocorreu um erro, tente novamente.");
		}
	}

	function firstPage() {
		setPagination({ ...pagination, pageIndex: 0 });
		setReloadPage(true);
	}

	function previousPage() {
		setPagination({ ...pagination, pageIndex: pagination.pageIndex - 1 });
		setReloadPage(true);
	}

	function nextPage() {
		setPagination({ ...pagination, pageIndex: pagination.pageIndex + 1 });
		setReloadPage(true);
	}

	const columns = [
		{
			Header: "",
			accessor: "check",

			Cell: ({ cell }) => {
				const { original } = cell.row;
				return (
					<Checkbox
						id={"checked_" + original.key}
						value={original.checked}
						onChange={(e) =>
							handleInputChange(e, original.key, "checked")
						}
						checked={original.checked}
					/>
				);
			},
		},
		{
			Header: (
				<Header width={'100px'}>
					<Title>NF3e</Title>
				</Header>
			),
			accessor: "key",
		},
		{
			Header: (
				<Header>
					<Title>Status</Title>
				</Header>
			),
			accessor: "status",
		},
		{
			Header: (
				<Header>
					<Title>Descrição</Title>
				</Header>
			),
			accessor: "description",
		},
		
		{
			Header: (
				<Header>
					<Title>UF</Title>
				</Header>
			),
			accessor: "state",
		},

		{
			Header: (
				<Header>
					<Title>CNPJ</Title>
				</Header>
			),
			accessor: "document",
		},
		{
			Header: (
				<Header>
					<Title>Data/Hora</Title>
				</Header>
			),
			accessor: "created",
			Cell: ({ cell }) => {
				const { original } = cell.row;
				return new Date(original.created).toLocaleString(["pt-BR"]);
			},
		},
		
		{
			Header: "",
			accessor: "download",

			Cell: ({ cell }) => {
				const { original } = cell.row;
				return (
					<>
					<Button
						style={{ height: 30, width: 50 }}
						type="button"
						small
						color="white"
						title="Mais informações"
						onClick={() => {
							setTableRow(original);
							handleToggleModal();
						}}
					>
						<InfoIcon />
					</Button>

					<Button
						style={{ height: 30 }}
						type="button"
						small
						title="Download NF3e"
						onClick={() => handleDownloadClick(original.key)}
					>
						<DownloadIcon />
					</Button>
					</>
				);
			},
		},
		
		
	];

	const opened = showInfoFilter !== "" && "opened";

	const handleToggleModal = () => {		
		setShowModal(!false);
	};
	const handleConfirmModalButton = () => {
		setShowModal(false);
	};

	return (
		<>
		
		<Modal
			showModal={showModal}
			modalTitle="Mais informações"
			handleToggleModal={handleToggleModal}
			handleConfirmModalButton={handleConfirmModalButton}
			hideFirstButton
		>
			<p>Lote: { tableRow.lot || 'N/C'}</p>
			<p>Evento: {tableRow.event || 'N/C'}</p>
			<p>Protocolo: {tableRow.protocol || 'N/C'}</p>

		</Modal>
		<AssetsMapHeader
			title="Monitor NF3e"
			handleButtonClick={handleButtonClick}
			selectedButton={showInfoFilter}
			handleSendDeleteButton={handleSendDeleteButton}
			showSendDeleteButton={showSendDeleteButton}
		/>

		<Container>
		
			<StyledCard>
				<Filter
					onChangeFilter={onChangeFilter}
					handleCloseButton={() => handleButtonClick("")}
					filter={filter}
					/>
			</StyledCard>
		
		</Container>
		
		<Container>
			<LeftPanel>
				<Table data={nfe} columns={columns} loading={loading} alignDescriptionAtNFe />
				<Pagination
					data={pagination}
					firstPage={firstPage}
					previousPage={previousPage}
					nextPage={nextPage}
				/>
			</LeftPanel>
		</Container>
		</>
	);
}


export default MonitorNfe;