const cnpjMask = (cnpj) => {
	let x = cnpj
		.replace(/\D/g, "")
		.match(/(\d{0,2})(\d{0,3})(\d{0,3})(\d{0,4})(\d{0,2})/);
	x = !x[2]
		? x[1]
		: x[1] +
		  "." +
		  x[2] +
		  "." +
		  x[3] +
		  "/" +
		  x[4] +
		  (x[5] ? "-" + x[5] : "");

	return x;
};

export { cnpjMask };
