import React, { useState } from "react";
import styled from "styled-components";

function Collapsible(props) {
  const { label, labelOpen, children } = props;
  const [open, setOpen] = useState(false);

  const ButtonToggle = styled.button`
    background: transparent;
    border: 0;
    color: #1f78ff;
    cursor: pointer;
    float: right;
  `;

  const toggle = () => {
    setOpen(!open);
  };

  return (
    <div>
      <div id="children">{open && children}</div>
      <ButtonToggle type="button" large onClick={toggle}>
        {open ? (
          <div>{labelOpen || "Menos Informações"}</div>
        ) : (
          <div>{label || "Mais Informações"}</div>
        )}
      </ButtonToggle>
    </div>
  );
}

export default Collapsible;
