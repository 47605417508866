import styled from "styled-components";

export default styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	/* font-size: 20px; */
	/* width: 100%; */
	max-width: 100%;
	max-height: min-content;
	/* justify-content: ${props => props.justifyContent};
	align-items: ${props => props.alignItems};
	flex-direction: row; */
`;
